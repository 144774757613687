@if $theme-name == "cutimed" {
  .footnotes {
    margin: 35px 0;
  }

  .footnotes-title {
    @include font-size-and-line-height(28px, 32px);

    margin-bottom: 30px;
    color: $color-gray;
    font-family: $fontfamily-regular-bold-condensed;
  }

  .footnotes-text {
    @include font-size-and-line-height(16px, 25px);

    color: $color-gray;
    margin-bottom: 0;
    font-family: $fontfamily-regular-light;
  }

  @media (max-width: $grid-bp-sm) {
    .footnotes-title {
      margin-bottom: 10px;
    }
  }
}