@if ($theme-name == "cutimed") {
  .highlight-section {
    padding: 40px 18px 20px;
    text-align: center;
    margin-bottom: 20px;

    @media (min-width: $grid-bp-sm) {
      text-align: left;
    }

    @media (min-width: $grid-bp-md) {
      padding: 28px 100px;
    }
  }

  .highlight-section-title {
    @include font-size-and-line-height($highlight-section-font-size, $highlight-section-line-height);

    font-family: $fontfamily-regular-bold-condensed;
    margin-bottom: 36px;

    @media (min-width: $grid-bp-md) {
      margin-bottom: 22px;
    }
  }

  .highlight-section-text {
    line-height: 25px;
  }
}