@if ($theme-name == "cutimed") {
  .event {
    display: flex;
    align-items: flex-start;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: $color-light-blue-2;
  }
  
  .event-circle {
    position: relative;
    text-align: center;
    margin-left: 20px;
  }

  .event-img {
    max-width: 150px;
    height: auto;
  }
  
  .event-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .event-month, .event-day {
    margin: 0;
    padding: 0;
    color: $color-blue;
  }

  .event-month {
    @include font-size-and-line-height(22px, 22px);

    font-family: $fontfamily-regular-light;
  }

  .event-day {
    @include font-size-and-line-height(45px, 45px);

    font-family: $fontfamily-regular-bold-condensed;
  }
  
  .event-text {
    max-width: 570px;
    color: $color-gray;
    margin-right: 20px;
    margin-left: 40px;
  }
  
  .event-title {
    @include font-size-and-line-height(35px, 42px);

    font-family: $fontfamily-regular-bold-condensed;
    margin-bottom: 30px;
  }
  
  .event-date {
    @include font-size-and-line-height(23px, 26px);
    
    font-family: $fontfamily-regular-bold-condensed;
  }

  .event-btn {
    @include font-size-and-line-height(15px, 20px);

    padding-left: 60px;
    padding-right: 60px;
    text-align: center;
    margin-top: 10px;
  }

  @media (max-width: $grid-bp-lg) {
    .event-btn {
      margin-right: 10px;
    }
  }

  @media (max-width: $grid-bp-md) {
    .event {
      flex-direction: column;
      text-align: center;
    }

    .event-circle {
      margin: 0 auto 30px;
    }

    .event-text, 
    .event-btn {
      margin: 0 auto;
    }
  }

  @media (max-width: $grid-bp-xs) {
    .event {
      padding-bottom: 50px;
    }

    .event-circle {
      margin-bottom: 40px;
    }

    .event-img {
      max-width: 250px;
    }

    .event-month {
      @include font-size-and-line-height(50px, 50px);
    }
  
    .event-day {
      @include font-size-and-line-height(100px, 100px);
    }

    .event-title {
      margin-bottom: 15px;
    }

    .event-btn {
      margin-top: 40px;
    }
  }
}
