@if ($theme-name == "cutimed") {
  .box-carousel-slide {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .box-slide-content {
    border: 2px solid $color-light-blue-2;
    border-top: none;
    margin-top: auto;
    height: inherit;
  }

  .box-slide-img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .box-slide-text,
  .box-carousel-disclaimer {
    @include font-size-and-line-height(16px, 25px);

    color: $color-gray;
    font-family: $fontfamily-regular;
  }

  .box-slide-text {
    margin: 30px 35px 50px;
  }

  .box-carousel-disclaimer {
    width: 100%;
  }

  .carousel-box .carousel-controls {
    margin-top: 15px;
  }

  @media (max-width: $grid-bp-sm) {
    .box-slide-text {
      margin: 15px 17px 50px;
    }
  }

  .carousel-box ~ .carousel-nav-button-prev,
  .carousel-box ~ .carousel-nav-button-next {
    display: none;

    @media (min-width: $grid-bp-md) {
      display: block;
      top: 26%;
    }

    @media (min-width: $container-max-width) {
      top: 30%;
    }
  }

  @media (min-width: $grid-bp-md) {
    .carousel-box ~ .carousel-nav-button-prev {
      left: -20px;
    }

    .carousel-box ~ .carousel-nav-button-next {
      right: -32px;
    }
  }
}
