// Theme-specific
$theme-name: "cutimed";
$is-tena-theme: false;

// Base
$line-height-paragraph: 22px;

// Colors
$color-blue: #004F9F;
$color-pink: #D6007A;
$color-light-blue-1: #A3CCEE;
$color-light-blue-2: #ECF4FC;
$color-gray-light: #DDE8F0;
$color-purple: #CFB2D6;
$color-gray: #575756;
$color-gray-medium: #939598;
$color-primary: $color-blue;
$accent-color: $color-blue;
$logo-color: $color-blue;
$footer-bg-color: $color-light-blue-2;
$background-color: $color-white;
$home-color: $color-gray;

// Font sizes
$font-size-h2: 35px;
$font-size-h3: 28px;
$font-size-h4: 21px;

// Caption
$caption-background-transparent: rgba(0, 79, 159, 0.5);

$btn-play-background: #AAA;
$btn-weight: bold;
$btn-play-background-transparent: rgba($btn-play-background, 0.75);

// Product Colors
$color-debriClean: #E84032;
$color-sorbact: #557D75;
$color-siltec-sorbact: #6FBC84;
$color-sorbion-sachet: #009FE3;
$color-expiona: #791F82;
$btn-padding-icon: $btn-padding;
$color-cuticell-classic: #98989A;
$border-color: $color-cuticell-classic;
$border-color-dropwdown: #979797;
$border-color-alt: $color-cuticell-classic;

// Crate
$crate-background-color: transparent;

// Typography
$fontfamily-helveticaNow: HelveticaNow, $fontfamily-fallback;
$fontfamily-helveticaNow-light: HelveticaNowLight, $fontfamily-fallback;
$fontfamily-helveticaNow-bold: HelveticaNowBold, $fontfamily-fallback;
$fontfamily-helveticaNow-extra-bold-condensed: HelveticaNowExtraBoldCondensed, $fontfamily-fallback;

$fontfamily-regular: $fontfamily-helveticaNow-light;
$fontfamily-regular-italic: $fontfamily-helveticaNow-light;
$fontfamily-regular-bold: $fontfamily-helveticaNow-bold;
$fontfamily-regular-bold-condensed: $fontfamily-helveticaNow-extra-bold-condensed;
$fontfamily-regular-light: $fontfamily-helveticaNow-light;
$fontfamily-header-thin: $fontfamily-regular;

$paragraph-line-height: 22px;
$font-size-header-title: (def: 16px);
$font-size-intro-carousel-title: (def: 35px, md: 28px);
$line-height-intro-carousel-title: (def: 42px, md: 32px);
$font-size-section-title: 35px;
$line-height-section-title: 42px;
$fontfamily-header: $fontfamily-regular-bold-condensed;
$intro-product-detail-title-font-size: 35px;
$font-size-btn: 16px;
$font-size-items-list: 16px;
$line-height-items-list: 22px;
$font-size-section-title-large: (def: 48px);
$line-height-section-title-large: (def: 48px);
$letter-spacing-section-title-large: unset;
$font-size-accordion-title: (def: 21px);
$line-height-accordion-title: (def: 25px);
$simple-list-heading-font-size: (def: 28px);
$simple-list-heading-line-height: (def: 32px);

// Buttons
$btn-primary-bg-color: $color-pink;
$btn-primary-text-color: $color-white;
$btn-secondary-bg-color: $color-blue;
$btn-secondary-text-color: $color-white;
$btn-secondary-font-size: 16px;
$btn-secondary-line-height: 22px;
$cta-button-background-color: $accent-color;
$cta-button-text-color: $color-white;
$cta-button-hover-text-color: $color-white;
$cta-button-hover-background-color: $color-pink;
$cta-bg-hover: $color-gray;
$btn-font-family: $fontfamily-regular-bold;
$btn-play-pause-background: rgba($color-black, 0.25);

// Header
$header-logo-width: 150px;
$font-size-menu-item: (def: 16px, sm: 18px);
$mobile-header-logo-width: 130px;
$breadcrumb-color: $color-gray;
$submenu-link-color: $color-gray;
$menu-submenu-bg-color: $color-light-blue-2;
$country-selector-border-color: $border-color;
$item-sample-title-font-size: (def: 15px);
$item-sample-title-line-height: (def: 20px);
$header-links-color: $color-gray;
$header-search-placeholder-font-family: $fontfamily-regular;
$mobile-header-search-close-icon-color: $color-gray;
$mobile-header-details-position-bottom: 12px;
$header-search-border-color: #97999B;
$header-country-selector-label-font-size: 12px;
$header-country-selector-label-line-height: 18px;
$header-country-selector-label-color: #575756;

// Footer
$footer-disclaimer-font-size: (def: 32px, sm: 21px);
$footer-disclaimer-line-height: (def: 36px, sm: 25px);
$footer-disclaimer-heading-font-family: $fontfamily-regular-bold;
$footer-disclaimer-heading-color: $color-blue;
$footer-disclaimer-footnote-line-height: (def: 28px, sm: 22px);
$footer-disclaimer-border-color: $color-blue;

// Intro
$intro-title-font-size: (def: 35px);
$intro-title-line-height: (def: 42px);
$intro-title-font-family: $fontfamily-regular-bold-condensed;
$intro-text-font-family: $fontfamily-regular;
$intro-line-height: 22px;
$intro-text-color: $home-color;
$intro-subtitle-color: $home-color;
$intro-pretitle-fontfamily: $fontfamily-helveticaNow;

// Carousel
$image-carousel-arrow-color: $color-gray;
$image-carousel-arrow-hover-color: $color-blue;
$mobile-header-menu-background: transparent;
$image-carousel-enlarge-stroke-color: $color-blue;
$swiper-pagination-color: transparent;
$swiper-pagination-border-color: $color-blue;
$swiper-pagination-active-color: $color-blue;
$swiper-pagination-active-border-color: $color-blue;
$swiper-pagination-color-hover: transparent;
$swiper-pagination-border-color-hover: $color-pink;
$swiper-pagination-active-color-hover: $color-blue;
$swiper-pagination-active-border-color-hover: $color-blue;

// Table
$table-row-odd-color: $color-white;
$table-row-even-color: $color-light-blue-2;
$table-light-border-color: $color-blue;
$table-row-odd-color-sm: $color-light-blue-2;
$table-row-even-color-sm: $color-gray-light;

// Article list
$article-list-title-font: $fontfamily-regular-bold;

// Banner
$font-size-caption-image: 28px;
$line-height-caption-image: 32px;

// Campaign image
$font-size-campaign-image-title-large: (def: 48px);
$line-height-campaign-image-title-large: (def: 48px);
$font-size-campaign-title: (def: 28px);
$line-height-campaign-image-title: (def: 32px);
$campaign-subtitle-font: $fontfamily-regular;
$campaign-media-title-font: $fontfamily-regular-bold-condensed;
$font-size-caption-image-header: (def: 48px, sm: 28px);
$line-height-caption-image-header: (def: 48px, sm: 32px);
$font-size-caption-image-subheader: 16px;
$campaign-light-background-color: $color-gray;
$campaign-buttons-margin: 12px;

// Product box
$product-box-text-margin: auto 20px;
$font-size-product-box-text: 16px;

// Sample order
$product-box-sample-text-color: $color-gray;
$product-box-sample-button-background: $color-blue;
$product-box-sample-button-background: $color-blue;

// Search
$search-border-color: #AAA;
$font-size-placeholder-site-search: 15px;
$input-search-placeholder-color-active: #BBB;
$btn-search-icons-position-top: 12px;
$pagination-selected-background-color: transparent;
$pagination-selected-hover-background-color: transparent;
$pagination-arrow-icon-color: $color-blue;
$input-search-border-color: #AAA;
$input-search-border-color-active: $input-search-border-color;
$btn-search-reset-position-top: 12px;

// Pushbox
$push-box-pretitle-transform: none;
$push-box-bg: $color-light-blue-2;
$push-box-border-color: $color-light-blue-2;
$push-box-border-radius: 3px;

// Call to action
$font-size-cta-title: 35px;
$line-height-cta-title: 42px;
$cta-title-transform: none;
$cta-title-font: $fontfamily-regular-bold-condensed;

// Sitemap
$sitemap-list-title-font-size: 23px;

// Call to action
$cta-background-color: $color-blue;
$cta-background-light-color: $color-light-blue-2;

// Product filter - Info panel
$info-panel-main-title-font: $fontfamily-header-thin;

// Testimonial banner
$testimonial-banner-heading-font-size: (def: 35px, sm: 28px);
$testimonial-banner-heading-line-height: (def: 40px, sm: 33px);

// Promo banner
$promo-banner-title-font: $fontfamily-regular-bold-condensed;
$promo-banner-title-font-size: (def: 35px, sm: 28px);
$promo-banner-title-line-height: (def: 42px, sm: 32px);
$promo-banner-title-font-weight: 400;
$promo-banner-desc-font-size: (def: 16px);
$promo-banner-desc-line-height: (def: 22px);
$promo-banner-desc-font: $fontfamily-regular;
$promo-banner-bg-color: $color-light-blue-1;

// Sample page
$sample-loading-title-font-size: (def: 48px, sm: 35px);
$sample-loading-title-line-height: (def: 48px, sm: 42px);
$sample-ordered-product-border-color: #A3CCEE;
$sample-ordered-note-border-color: #ECF4FC;
$sample-ordered-note-font-family: $fontfamily-helveticaNow;
$sample-ordered-product-title-font-family: $fontfamily-asap;
$sample-ordered-product-title-color: #303030;
$sample-selected-info-bg-color: #ECF4FC;
$sample-order-category-bg-color: #ECF4FC;
$sample-order-category-text-color: #575756;
$sample-order-category-btn-bg-color: #D6007A;
$sample-order-category-text-fontfamily: $fontfamily-helveticaNow-bold;
$sample-order-section-title: #575756;
$sample-ordered-product-bg-color: $color-light-blue-2;

// Quotation (Testimonial)
$testimonials-quote-font-size: (def: 35px, sm: 28px);
$testimonials-quote-line-height: (def: 42px, sm: 32px);
$testimonials-quote-font-family: $fontfamily-regular-bold-condensed;
$testimonials-quote-color: $color-gray;
$testimonials-quote-letter-spacing: unset;
$testimonials-quote-info-font-family: $fontfamily-regular;
$testimonials-quote-info-color: $color-blue;
$testimonials-quote-left-img: '../img/quotation/ic-quotation-cutimed-left.png';
$testimonials-quote-right-img: '../img/quotation/ic-quotation-cutimed-right.png';

// Country list submenu
$country-language-selected-color: #004F9F;